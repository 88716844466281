.responsive-content-layout {
    max-width: 750px;
    margin: auto;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div[data-css="row"] {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

div[data-css="row"] > * {
    margin: 0 0 0 10px;
}

div[data-css="col"] {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

div[data-css="col"] > * {
    margin: 0 0 20px 0;
}

div[data-css="col"]:first-child {
    margin: 20px 0 0 0;
}

div[data-css="mobile-col"] {
    display: flex;
    flex-direction: column-reverse;
}

div[data-css="mobile-col"]:first-child {
    margin: 20px 0 0 0;
}

div[data-css="mobile-col"] > * {
    margin: 0 0 20px 0;
    height: 50px;
}

@font-face{
    font-family: 'text-security-disc';
    src: url('./assets/fonts/text-security-disc-compat.eot');
    src: url('./assets/fonts/text-security-disc-compat.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/text-security-disc-compat.woff2') format('woff2'),
    url('./assets/fonts/text-security-disc-compat.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

div[data-css="password"] > input {
    /* Workaround to allow us to use type=number input field */
    font-family: 'text-security-disc' !important;
    /* Use -webkit-text-security if the browser supports it */
    -webkit-text-security: disc;
    /* Hide up/down arrows on Firefox */
    -moz-appearance:textfield;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    /* Disable up/down arrows for type=number input field */
    -webkit-appearance: none;
    margin: 0;
}

div[data-css="numeric"] > input {
    /* Hide up/down arrows on Firefox */
    -moz-appearance:textfield;
}
