:root {
    --overlay-background: rgba(16, 16, 16, 0.5);
    --lightGrey: #f3f3f3;
    --grey: #A7A7A7;
    --white: #FFFFFF;
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    background-color: var(--overlay-background);
    display: flex;
    justify-content: flex-end;
}

.spinner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    margin: 80px 25px 25px 25px;
}

.spinner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid var(--lightGrey);
    border-top: 1px solid var(--grey);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    animation: spin 1s linear infinite;
}

.spinner h1 {
    color: var(--white) !important;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
